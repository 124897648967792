import "./footer.css"
import {Link} from 'react-router-dom';

function Footer() {


    return (
        <section id="footer" className="container">
            <p>
                Allventures sp. z o. o., ul. Wielicka 42 / B3, 30-552 Kraków<br></br>
                KRS: 0001034424<br></br>
                NIP: 6793266456<br></br>
                REGON: 525230529
            </p>
            <ul>
                <li><a href="/regulamin_sklepu.pdf" target="_blank">Regulamin</a></li>
                <li><a href="/polityka_prywatnosci_sklepu.pdf" target="_blank">Polityka prywatności</a></li>
            </ul>
        </section>
    );
}

export default Footer;