import {useTranslation} from "react-i18next";
import {LANGUAGES} from "../../constants/index";
import './menu.css';
import {useState} from 'react';
import createdBy from '../../assets/images/created_by.png';
import {Link} from 'react-router-dom';

const isActive = ({isActive}) => `link ${isActive ? "active" : ""}`;

export const Menu = () => {
    const {i18n, t} = useTranslation();

    const [menuVisible, setMenuVisible] = useState(false);

    const onChangeLang = (e) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
    };

    return (
        <nav className="row">
            <a href="/"><img src={createdBy} alt="Allventures"/></a>
            <div className="flex-row-center">
                <i className="fa-solid fa-bars hamburger-icon" onClick={() => setMenuVisible(!menuVisible)}></i>
                <div
                    className={`menu-options flex-row-center dot-separated-list ${menuVisible ? 'visible' : 'hidden'}`}>
                    {/*<Link to="/advert" onClick={() => setMenuVisible(!menuVisible)}>REKLAMA</Link>*/}
                    {/*<Link to="/support" onClick={() => setMenuVisible(!menuVisible)}>SUPPORT</Link>*/}
                    <Link to="/" onClick={() => setMenuVisible(!menuVisible)}><i className="fa fa-house"></i></Link>
                    <Link to="/game" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.game')}</Link>
                    <Link to="/loyality-program" onClick={() => setMenuVisible(false)}>{t('menu.loyality-program')}</Link>
                    <Link to="/internship" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.internship')}</Link>
                    <Link to="/data" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.data')}</Link>
                    <Link to="/shop" onClick={() => setMenuVisible(!menuVisible)}><i className="fa fa-cart-shopping"></i></Link>

                    <select defaultValue={i18n.language} onChange={onChangeLang}>
                        {LANGUAGES.map(({code, label}) => (
                            <option key={code} value={code} >
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

        </nav>
    );
};