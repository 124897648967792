import {useTranslation} from "react-i18next";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules';
import 'swiper/css/autoplay';
import "./home.css"
import 'swiper/css';

import warszawa from '../../assets/images/cities/warszawa.jpeg';
import krakow from '../../assets/images/cities/krakow.png';
import gdansk from '../../assets/images/cities/gdansk.jpeg';
import praga from '../../assets/images/cities/praga.jpeg';
import paris from '../../assets/images/cities/paris.jpeg';
import wroclaw from '../../assets/images/cities/wroclaw.jpeg';
import bari from '../../assets/images/cities/bari.jpeg';

import tikTok from '../../assets/images/svg/tiktok.svg';
import instagram from '../../assets/images/svg/instagram.svg';
import linkedin from '../../assets/images/svg/linkedin.svg';

import KITS from '../../assets/images/success/KITS.png';
import cassini from '../../assets/images/success/cassini.svg';
import sun from '../../assets/images/success/sun.png';
import kpt from '../../assets/images/success/kpt.svg';
import kms from '../../assets/images/success/kms.png';

import panel from '../../assets/panel.png';
import mainScreen from '../../assets/images/phone-01.png';
import trawellLogo from '../../assets/images/trawell_logo.svg';

import {About} from "../about/about";
import {Card} from "../../components/card/card";
import {HowItWorks} from "../../components/how-it-works/how-it-works";
import {Tags} from "../../components/tags/tags";
import {Offert} from "../../components/offert/offert";
import {Screenshots} from "../../components/screenshots/screenshots";

import createGame from '../../assets/images/offert1/panel.png';
import visitPlaces from '../../assets/images/offert1/gra.gif';
import ranking from '../../assets/images/offert1/ranking.png';
import Footer from "../../components/footer/footer";

export const Home = () => {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const steps = [
        {
            "id": 1,
            "imgSrc": createGame,
            "headingKey": "step1heading",
            "textKey": "step1"
        },
        {
            "id": 2,
            "imgSrc": visitPlaces,
            "headingKey": "step2heading",
            "textKey": "step2"
        },
        {
            "id": 3,
            "imgSrc": ranking,
            "headingKey": "step3heading",
            "textKey": "step3"
        }
    ];
    return (
        <>
            <main className="main-content">
                <div className="row main-wrapper flex-column-center-center first-container">
                    <div className="sub-main-wrapper">
                        <div className="main-description">
                            <img src={trawellLogo} alt="Trawell"></img>
                            <h1 className="main-title">{t('appTagline')}</h1>
                            <p dangerouslySetInnerHTML={{__html: t('appDescription')}}></p>
                            <div className="buttons">
                                <a href="mailto:adrian.widlak@adrianwii.pl" className="button button-main">
                  <span className="material-icons">
                    mail
                  </span>
                                </a>
                                <a href="https://www.linkedin.com/company/86671615" target="_blank"
                                   className="button button-secondary">
                                    <img src={linkedin} alt="Trawell linkedin"></img>
                                </a>
                                <a href="https://www.instagram.com/trawell_allventures" target="_blank"
                                   className="button button-tertiary">
                                    <img src={instagram} alt="Trawell instagram"></img>
                                </a>
                                <a href="https://www.tiktok.com/@trawell_allventures" target="_blank"
                                   className="button button-tertiary">
                                    <img src={tikTok}></img>
                                </a>
                                <a href="https://apps.apple.com/pl/app/trawell/id6450898028">
                                    <img
                                        className="apple"
                                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                                        alt="Download on the App Store"></img>
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.allventures.trawell'>
                                    <img
                                        className="android"
                                        alt='Get it on Google Play'
                                        src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'></img>
                                </a>
                            </div>
                        </div>
                        <div className="main-mockup flex-column-center-center">
                            <img src={mainScreen} alt="mobile app"></img>
                            <div className="element-background animation-rotation-gradient"></div>
                        </div>
                    </div>
                </div>
            </main>

            <section id="projects" className="container">
                <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        900: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        1000: {
                            slidesPerView: 2,
                            spaceBetween: 25
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 25
                        },
                        1700: {
                            slidesPerView: 4,
                            spaceBetween: 25
                        },
                        2000: {
                            slidesPerView: 5,
                            spaceBetween: 50
                        }
                    }}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 1500,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                    }}
                    loop
                >

                    <SwiperSlide>
                        <Card
                            image={krakow}
                            name={t('krakow')}
                        ></Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            image={warszawa}
                            name={t('warszawa')}
                        ></Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            image={gdansk}
                            name={t('gdansk')}
                        ></Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            image={praga}
                            name={t('prague')}
                        ></Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            image={paris}
                            name={t('paris')}
                        ></Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            image={wroclaw}
                            name={t('wroclaw')}
                        ></Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card
                            image={bari}
                            name={t('bari')}
                        ></Card>
                    </SwiperSlide>
                </Swiper>

            </section>

            <Offert
                heading={t('steps')}
                paragraph={t('stepsDescription')}
                image={panel}
                buttonText={t('stepsCTA')}
                buttonCta=""
            ></Offert>

            <div className="row white-background">
                <Tags tags={[
                    t('offert1_tag1'),
                    t('offert1_tag2'),
                    t('offert1_tag3'),
                    t('offert1_tag4')
                ]}/>

                <HowItWorks
                    header="howItWorks"
                    steps={steps}
                ></HowItWorks>

                <h2 dangerouslySetInnerHTML={{__html: t('offertCarouselHeading')}}></h2>

                <div className="pricing">
                    <div className="price-box">
                        <div>
                            <p>
                                <b>BASIC</b><br></br>
                                {t('offert1price')}
                            </p>

                        </div>
                        <p dangerouslySetInnerHTML={{__html: t('offert1')}}/>
                    </div>

                    <div className="price-box">
                        <div className="blue-background">
                            <p>
                                <b>PRO</b><br></br>
                                {t('offert2price')}
                            </p>
                        </div>
                        <p dangerouslySetInnerHTML={{__html: t('offert2')}}/>
                    </div>

                    <div className="price-box">
                        <div>
                            <b>UNLIMITED</b><br></br>
                            {t('offert3price')}
                        </div>
                        <p dangerouslySetInnerHTML={{__html: t('offert3')}}/>
                    </div>
                </div>

                <Screenshots language={currentLanguage}/>

            </div>


            <section id="success" className="container">
                <h1>{t("ourSuccess")}</h1>
                <div>
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            400: {
                                slidesPerView: 2,
                                spaceBetween: 10
                            },
                            639: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            },
                            950: {
                                slidesPerView: 3,
                                spaceBetween: 50
                            },
                            1000: {
                                slidesPerView: 4,
                                spaceBetween: 50
                            }
                        }}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 1500,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false
                        }}
                        loop
                    >
                        <SwiperSlide>
                            <img src={cassini}></img>
                            <p>{t("cassiniWinner")}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={kpt}></img>
                            <p>KPT Pitch Fighting Club</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={sun}></img>
                            <p>SUN Hubs Netowrk Incubator</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={KITS}></img>
                            <p>KrK Innotech Starter</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={kms}></img>
                            <p>{t("audienceWinner")}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={kms}></img>
                            <p>{t("juryWinner")}</p>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section id="about" className="container">
                <About/>
            </section>

            <Footer></Footer>
        </>
    );
};